import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavbarOptions from './nav/navbarOptions'
import {Icon} from 'semantic-ui-react';


class Herosection7 extends Component {
    render() {
        return (

            <div className="row align-items-center">
                                <NavbarOptions />

                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}

                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Solve all cabling needs</h5>
                    <h1 className="display-4">
                    Voice & <span className="text-primary">Data</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted"><ul><li>Unparalleled expertise on installation and planning of voice and data systems in Florida. </li><li>Guaranteed professional results due to the extensive knowledge of our technicians.</li><li>Our structured cabling design and installation is governed by a set of standards that specify the proper wiring of buildings and residences.</li>
                   </ul></p>                    {/* Buttons */} <Link to="/" className="btn btn-outline-primary btnAcsIntro">
                  <Icon className="arrow circle left large iconResizeUp"></Icon>

</Link>

<Link to="/contact" className="btn btn-outline-success btnAcsIntro">
Contact us
</Link>
<Link to="/moreInfo" className="btn btn-outline-info btnAcsIntro">
More Info
</Link>
<Link to="/bookSiteSurvey" className="btn btn-outline-info btnAcsIntro">
Book a site survey
</Link>
                           
                            
                            
                    {/* <Clientlogo logoitems={4} margintop={10} /> */}
                </div>
            </div>

        );
    }
}

export default Herosection7;