import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavbarOptions from './nav/navbarOptions'
import MainImage from "../../assets/images/hero/cameraSystemCloud.png";
import {Icon} from 'semantic-ui-react';

class Herosection2 extends Component {
    render() {
        return (

            <div className="row align-items-center">
                                <NavbarOptions />

                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}

                    <img
className="img-fluid"
src={MainImage}
alt=""
/> 
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Solve all cabling needs</h5>
                    <h1 className="display-4">
                    CCTV <span className="text-primary">Products</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted">The highest quality CCTV products for the best price, from the top providers of the nation.</p><p className="lead text-muted"> Check below some of the brands of product manufacturers we work with and contact us to get started. </p>
                    {/* Buttons */}  <Link to="/" className="btn btn-outline-primary btnAcsIntro">
                  <Icon className="arrow circle left large iconResizeUp"></Icon>

</Link>

<Link to="/contact" className="btn btn-outline-success btnAcsIntro">
Contact us
</Link>
<Link to="/moreInfo" className="btn btn-outline-info btnAcsIntro">
More Info
</Link>
<Link to="/bookSiteSurvey" className="btn btn-outline-info btnAcsIntro">
Book a site survey
</Link>
                            
                    {/* <Clientlogo logoitems={4} margintop={10} /> */}
                </div>
            </div>

        );
    }
}

export default Herosection2;