import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SamsungLogo from "../../assets/images/logo/samsungLogo.gif";
import PelcoLogo from "../../assets/images/logo/pelcoLogo.gif";
import ActiLogo from "../../assets/images/logo/actiLogo.png";
import EverFocus from "../../assets/images/logo/everFocus.jpeg";
import SonyLogo from "../../assets/images/logo/sonyLogo.jpeg";
// import ValcomLogo from "../../assets/images/logo/valcomLogo.png";
import Background   from '../../assets/images/bg/02.png';

class CctvProducts extends Component {
    render() {
        return (
            <section className="custom-pt-1 custom-pb-2 bg-dark position-relative" data-bg-img={Background}>
                    <div className="container">
                        <div className="row align-items-end">
                        {/* <div className="col-lg-4 col-md-6 mb-8 mb-lg-0 text-white">
                            <div> <span className="badge badge-warning-soft p-2">
                                <i className="la la-plug ic-3x rotation" />
                            </span>
                            <h2 className="mt-3 mb-0">Experienced professionals, best prices and fast service</h2>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={SonyLogo} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Sony 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Sony &#174; products, just contact us.</p>
                         
                            
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6 mt-md-0">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={ActiLogo} alt="" />
                                </div>
                                <h5 className="m-0 text-light">ACTi	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of ACTi &#174; products, just contact us.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={PelcoLogo} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Pelco 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Pelco &#174; products, just contact us.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={EverFocus} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Ever Focus 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Ever Focus &#174; products, just contact us.</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={SamsungLogo} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Samsung	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Samsung &#174; products, just contact us.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    </section>
                   
        );
    }
}

export default CctvProducts;