import React, { Component, Fragment } from 'react';
// import { HashRouter, Switch, Route ,withRouter} from 'react-router-dom';
// import Header from './layout/header/header';
import Footer from './layout/footer/footer';
import Header from './layout/header/header';

import {BrowserRouter as Router,
  Switch,
  Route, 
  Link } from "react-router-dom"; 

import $ from 'jquery';

import './App.css';
// import './Vendor.js';
import './Vendor.js';


import Index2 from './pages/Index/index2';
import Index1 from './pages/Index/index1';
import Index3 from './pages/Index/index3';
import Index4 from './pages/Index/index4';
import Index6 from './pages/Index/index6';
import Index7 from './pages/Index/index7';
import Index8 from './pages/Index/index8';
import Index9 from './pages/Index/index9';
import Index10 from './pages/Index/index10';
import Index11 from './pages/Index/index11';
import Index12 from './pages/Index/index12';
import Index13 from './pages/Index/index13';
import Index14 from './pages/Index/index14';
import Index15 from './pages/Index/index15';
import Index16 from './pages/Index/index16';
import Index17 from './pages/Index/index17';


import Contact from './pages/Index/contact';
import Accordion from './pages/Index/accordion';
import Dropdown from './pages/Index/dropdown';

import Scrolltop from './layout/back-to-top';



class App extends React.Component {


  
//   UNSAFE_componentWillMount()
// {}
//   getUrl(pathname) {
//     let pathArray = pathname.split('/');
//     return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
//   }
//   setHeader(pathname) {
//     let pathArray = pathname.split('/');
//     return `/${pathArray[1]}` === '/index2' ? true : `/${pathArray[1]}` === '/index3' ? true : `/${pathArray[1]}` === '/index4' ? true : `/${pathArray[1]}` === '/index5' ? true : `/${pathArray[1]}` === '/index9' ? true  : false;
 
//   }
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Index1} />
            <Route exact path="/avProducts" component={Index2} />
            <Route exact path="/cctvProducts" component={Index3} />
            <Route exact path="/voice+DataProducts" component={Index4} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/accordion" component={Accordion} />
            <Route exact path="/dropdown" component={Dropdown} />

            <Route exact path="/bookSiteSurvey" component={Index16} />
            <Route exact path="/moreInfo" component={Index17} />

            <Route exact path="/cameraSystems" component={Index6} />
            <Route exact path="/demarcExtensions" component={Index7} />
            <Route exact path="/voice+Data" component={Index8} />
            <Route exact path="/audioSystems" component={Index9} />
            <Route exact path="/projectorSetup" component={Index10} />
            <Route exact path="/wapInstall" component={Index11} />
            <Route exact path="/fiberOptics" component={Index12} />
            <Route exact path="/outsidePlant" component={Index13} />



            {/* to be determined */}
            <Route exact path="/posWiring" component={Index14} />
     




          </Switch>
          </Router>
          <Footer />
          <Scrolltop />
        </div>
      </Fragment>
    );
  }
}

// const AppMapStateToProps = state => {
//   return {
//     products: state.data.products
//   };
// };

// const AppMapDispatchToProps = dispatch => {
//   return {
//     getProducts: () => {
//       dispatch(getProducts());
//     }
//   };
// };


export default App;

