import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import acsIcon from "../../assets/images/logo/acsLogo.png";
import MainImage from "../../assets/images/hero/engineerCable.PNG";
import axios from 'axios';
import NavbarOptions from './nav/navbarOptions';
import {Icon} from 'semantic-ui-react';

class Herosection14 extends Component {
    render() {
        const handleOnSubmit = (e) => {
            e.preventDefault();
            const form = e.target;
            // setServerState({ submitting: true });
            axios({
              method: "post",
              url: "https://formspree.io/f/xoqypzyb",
              data: new FormData(form),
            })
              .then((r) => {
                // handleServerResponse(true, "Thanks!", form);
                console.log("cool");
              })
              .catch((r) => {
                console.log("cool");
                // handleServerResponse(false, r.response.data.error, form);
              });
          };
        
        return (

            <div className="row align-items-center">
                                <NavbarOptions />

                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}
<img
className="img-fluid padding-img-top"
src={acsIcon}
alt=""
/>    
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Solve all cabling needs</h5>
                    <h1 className="display-4">
                        Schedule a <span className="text-success">Call</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted">Send us your email and a brief description of how we can help you, and we'll get back to you shortly</p>
                    {/* Buttons */} 
                    <form onSubmit={handleOnSubmit} >
                
                <input
                  type="email"
                  className="form-input"
                  id="emailInput"
                  placeholder="name@example.com"
                  name="email"
                ></input>
                   <input
                  type="text"
                  className="form-input"
                  id="descriptionInput"
                  placeholder="Reason for contacting us..."
                  name="description"
                ></input>
                <button type="submit" className="btn btn-success">
                Submit
                </button>
              </form>


              <Link to="/" className="btn btn-outline-primary btnAcsIntro">
                  <Icon className="arrow circle left large iconResizeUp"></Icon>

</Link>

<Link to="/contact" className="btn btn-outline-success btnAcsIntro">
Contact us
</Link>
<Link to="/moreInfo" className="btn btn-outline-info btnAcsIntro">
More Info
</Link>
<Link to="/bookSiteSurvey" className="btn btn-outline-info btnAcsIntro">
Book a site survey
</Link>
                            
                            
                    {/* <Clientlogo logoitems={4} margintop={10} /> */}
                </div>
            </div>

        );
    }
}

export default Herosection14;