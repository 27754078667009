import React, { Component } from 'react';
import Services from '../../widgets/index/services';

import Herosection from '../../widgets/herosection/herosection';

class Index extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <section>
                    <div className="container">
                        <Herosection />
                    </div>
                </section>
                <div className="page-content">
                    {/*feature start*/}
                    {/* <Feature /> */}
                    {/*feature end*/}
                    
                    {/*about start*/}
                    {/* <About /> */}
                    {/*about end*/}
                    {/*services start*/}
                    <Services />
                    {/*services end*/}
                    {/*work start*/}
                    {/* <Work /> */}
                    {/*work end*/}
                    {/*testimonial start*/}
                    {/*testimonial end*/}
                    {/*blog start*/}
                    {/*blog end*/}
                </div>
            </div>
        );
    }
}

export default Index;

