import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import acsIcon from "../../assets/images/logo/acsLogo.png";
import MainImage from "../../assets/images/hero/engineerCable.PNG";
import axios from 'axios';
import NavbarOptions from './nav/navbarOptions';
import {Icon} from 'semantic-ui-react';
class Herosection15 extends Component {
    render() {
        const handleOnSubmit = (e) => {
            e.preventDefault();
            const form = e.target;
            // setServerState({ submitting: true });
            axios({
              method: "post",
              url: "https://formspree.io/f/xoqypzyb",
              data: new FormData(form),
            })
              .then((r) => {
                // handleServerResponse(true, "Thanks!", form);
                console.log("cool");
              })
              .catch((r) => {
                console.log("cool");
                // handleServerResponse(false, r.response.data.error, form);
              });
          };
        
        return (

            <div className="row align-items-center">
                                <NavbarOptions />

                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}
<img
className="img-fluid padding-img-top"
src={acsIcon}
alt=""
/>    
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Solve all cabling needs</h5>
                    <h1 className="display-4">
                        Book a <span className="text-success">site survey</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted">Let us know your preferred date and address, along with any details you want to share about the project, and an expert from our team will reach back shortly to the email you provide.</p>
                    {/* Buttons */} 
                    <form onSubmit={handleOnSubmit} >
                
                    <input
                  type="text"
                  className="form-input disappearElem"
                  id="moreInfoInput"
                  placeholder="book site survey"
                  name="typeOfForm"
                  value="Book a site survey form"
                ></input>
                   <input
                  type="date"
                  className="form-input"
                  id="dateInput"
                  name="date"
                  required
                ></input>
<textarea rows="5" cols="60"
                  className="form-input"
                  id="addressInput"
                  placeholder="address for the site here"
                  name="address"
                  required

                ></textarea>
                                <textarea rows="5" cols="60"
                  className="form-input"
                  id="descriptionInput"
                  placeholder="Reason for contacting us..."
                  name="description"
                  required
                  
                ></textarea>
                <input
                  type="email"
                  className="form-input"
                  id="emailInput"
                  placeholder="yourEmail@email.com"
                  name="email"
                  required
                ></input>
                <button type="submit" className="btn btn-success">
                Submit
                </button>
              </form>


              <Link to="/" className="btn btn-outline-primary btnAcsIntro">
                  <Icon className="arrow circle left large iconResizeUp"></Icon>

                            </Link>

                            <Link to="/contact" className="btn btn-outline-success btnAcsIntro">
                    Contact us
                            </Link>
                            <Link to="/moreInfo" className="btn btn-outline-info btnAcsIntro">
                        More Info
                            </Link>
                            
                            
                    {/* <Clientlogo logoitems={4} margintop={10} /> */}
                </div>
            </div>

        );
    }
}

export default Herosection15;