import React, { Component } from 'react';
import Services from '../../widgets/index/services';

import HeroSection5 from '../../widgets/herosection/herosection5';

class Contact extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <section>
                    <div className="container">
                        <HeroSection5 />
                    </div>
                </section>
                <div className="page-content">
                    <Services />
                </div>
            </div>
        );
    }
}

export default Contact;

