import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Leviton from "../../assets/images/logo/levitonLogo.jpeg";
import Legrand from "../../assets/images/logo/legrand.png";
import Siemon from "../../assets/images/logo/siemonLogo.png";
import Hitachi from "../../assets/images/logo/hitachiLogo.png";
import Chatsworth from "../../assets/images/logo/chatsworthLogo.jpeg";
import GeneralCable from "../../assets/images/logo/generalCable.jpeg";
import OpticalCable from "../../assets/images/logo/opticalCableCorp.jpeg";
import Hubell from "../../assets/images/logo/hubbell.png";
import Mohawk from "../../assets/images/logo/mohawkLogo.jpeg";
import Background   from '../../assets/images/bg/02.png';

class VoiceDataProducts extends Component {
    render() {
        return (
            <section className="custom-pt-1 custom-pb-2 bg-dark position-relative" data-bg-img={Background}>
                    <div className="container">
                        <div className="row align-items-end">
                        {/* <div className="col-lg-4 col-md-6 mb-8 mb-lg-0 text-white">
                            <div> <span className="badge badge-warning-soft p-2">
                                <i className="la la-plug ic-3x rotation" />
                            </span>
                            <h2 className="mt-3 mb-0">Experienced professionals, best prices and fast service</h2>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Legrand} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Legrand	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Legrand &#174; products, just contact us.</p>
                           
                            
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6 mt-md-0">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Siemon} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Siemon	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Siemon &#174; products, just contact us.</p>
                           
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Hitachi} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Hitachi Cable Manchester	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of HCM &#174; products, just contact us.</p>
                            
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Leviton} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Leviton	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Leviton &#174; products, just contact us.</p>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Chatsworth} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Chatsworth Products Inc	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Chatsworth &#174; products, just contact us.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={GeneralCable} alt="" />
                                </div>
                                <h5 className="m-0 text-light">General Cable 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of General Cable products	&#174;, just contact us.</p>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={OpticalCable} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Optical Cable Corporation	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Optical Cable Corporation &#174; products, just contact us.</p>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Hubell} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Hubell 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Hubell &#174; products, just contact us.</p>
                            <br />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={Mohawk} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Mohawk 	&#174;	</h5>
                            </div>
                            <p className="mb-0">Sale and installation expertise of Mohawk &#174; products, just contact us.</p>
                            <br />
                           </div>
                        </div>
                        </div>

                    </div>
                    <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    </section>
                   
        );
    }
}

export default VoiceDataProducts;