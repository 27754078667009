import React, { Component } from 'react';
import Button from 'bootstrap';
import { Link } from 'react-router-dom';
import MainImage from "../../assets/images/hero/engineerCable.PNG";
import { Menu, Dropdown } from 'semantic-ui-react'
import NavbarOptions from './nav/navbarOptions';



  
class Herosection extends Component {
    render() {
        
        return (

            <div className="row align-items-center">
                <NavbarOptions />
               
                <div className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}
<img
className="img-fluid"
src={MainImage}
alt=""
/>    
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                
                    <br />
                    {/* Heading */}
                    <h5 className="badge badge-primary-soft font-w-6">Solve all cabling needs</h5>
                    <h1 className="display-4">
                        We can provide the <span className="text-primary">solutions</span>
                    </h1>
                    {/* Text */}
                    <p className="lead text-muted">With 20+ years in the industry, we've helped hundreds of businesses &amp; clients across Florida with their cabling and communication needs.</p>
                    {/* Buttons */} 
                   

                    
                    
                    

{/* previous buttons removed until future update */}


                            {/* <Link to="/" className="btn btn-outline-primary btnAcsIntro">
                        Network Cabling
                            </Link><Link to="/" className="btn btn-outline-secondary btnAcsIntro">
                        Sound Systems
                            </Link><Link to="/index2" className="btn btn-outline-danger btnAcsIntro">
                        Camera Systems
                            </Link><Link to="/index6" className="btn btn-outline-danger btnAcsIntro">
                        Access Control
                            </Link><Link to="/index4" className="btn btn-outline-info btnAcsIntro">
                        Wireless (WIFI)
                            </Link><Link to="/index3" className="btn btn-outline-success btnAcsIntro">
                        Structured Cabling
                            </Link><Link to="/" className="btn btn-outline-success btnAcsIntro">
                        Data Centers
                            </Link><Link to="/" className="btn btn-outline-info btnAcsIntro">
                        Fiber Optic
                            </Link> */}
                            
                    {/* <Clientlogo logoitems={4} margintop={10} /> */}
                </div>
            </div>

        );
    }
}

export default Herosection;