import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import acsIcon from "../../assets/images/logo/acsLogo.png";

class Footer extends Component {
    render() {
        return (
            <footer className="py-11  position-relative" data-bg-img="assets/images/bg/03.png">
                <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                <svg className="svgBackground" viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                </svg>
                </div>
                <Container  className=" mt-11">
                <Row>
                    <div className="col-12 col-lg-5 col-xl-4 mr-auto mb-6 mb-lg-0">
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7">
                    <Row>
                    <Col className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                        <h4 className="mb-4 text-white">About us</h4>
                        <ul className="navbar-nav list-unstyled mb-0">
                            <li className="mb-3 nav-item"><a className=" whiteText" href="/contact">Contact us</a> 
                            </li>
                            <li className="mb-3 nav-item"><a className=" whiteText" href="/moreInfo">More Info</a> 
                            </li>
                            <li className="nav-item"><a className=" whiteText" href="/bookSiteSurvey">Book a site survey</a> 
                            </li>
                        </ul>
                        </Col>
                    <Col className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                        <h4 className="mb-4 text-white">Products</h4>
                        <ul className="navbar-nav list-unstyled mb-0">
                        <li className="mb-3 nav-item"> <a className="whiteText" href="cctvProducts">CCTV Products</a>

                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="voice+dataProducts">Voice & Data Products</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="avProducts">AV Products</a>
                            </li>
                        </ul>
                        </Col>
                        <Col className="col-12 col-sm-4 navbar-dark">
                        <h4 className="mb-4 text-white">Services</h4>
                        <ul className="navbar-nav list-unstyled mb-0">
                        <li className="mb-3 nav-item"><a className="whiteText" href="cameraSystems">Camera Systems</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="demarcExtensions">Demarc Extensions</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="voice+data">Voice & Data</a>
                            </li>
                         
                            <li className="mb-3 nav-item"><a className="whiteText" href="audioSystems">Audio Systems</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="projectorSetup">Projector Setup</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="posWiring">POS Wiring</a> 
                            </li>
 
                            <li className="mb-3 nav-item"><a className="whiteText" href="wapInstall">WAP Install</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="fiberOptics">Fiber Optics</a>
                            </li>
                            <li className="mb-3 nav-item"><a className="whiteText" href="outsidePlant">Outside Plant</a>
                            </li>
                        </ul>
                        </Col>    
                      
                    </Row>
                    <Row className="mt-5" >
                        <Col className="col-12 col-sm-6">
                            <Link className="footer-logo text-white h2 mb-0" to="/">
                            <img
            className="logoNav"
            src={acsIcon}
            alt=""
          />                                     </Link> 
                        </Col>
                        <Col className="col-12 col-sm-6 mt-6 mt-sm-0">
                        {/* <ul className="list-inline mb-0">
                            <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-facebook" /></Link> 
                            </li>
                            <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-dribbble" /></Link> 
                            </li>
                            <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-instagram" /></Link> 
                            </li>
                            <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-twitter" /></Link> 
                            </li>
                            <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-linkedin" /></Link> 
                            </li>
                        </ul> */}
                        </Col>
                    </Row>
                    </div>
                </Row>
                <Row className="text-white text-center mt-8">
                    <Col>
                    <hr className="mb-8" />Copyright 2021 All Cable Solutions
                    </Col>
                </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;