import React from 'react';
import PatrickCard from "../../../assets/images/businessCards/patrick.jpg";
import PaulCard from "../../../assets/images/businessCards/paul.jpg";
import { Divider } from 'semantic-ui-react'

const ContactCards = () => {
    
    return(
        <div className="col-12">
            <div className="row">
                <div className="col-12">

                <img
className="img-fluid"
src={PatrickCard}
alt=""
/>   
<h3>
<a href="tel:4073047481">Call at: +407 304 7481</a>
</h3>
<h3><a href = "mailto: Patrick@allcablesolutionsfl.com">Send an email to: Patrick@allcablesolutionsfl.com</a>
</h3>
<Divider section />

                </div>

                <div className="col-12">
                <img
className="img-fluid"
src={PaulCard}
alt=""
/>   

<h3>
<a href="tel:4073359102">Call at: +407 335 9102</a>
</h3>
<h3><a href = "mailto: Paul@allcablesolutionsfl.com">Send an email to: Paul@allcablesolutionsfl.com</a>
</h3>

                </div>
            </div>
        </div>
    )
}

export default ContactCards;