import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react'


const contactOptions = [
    { key: 'book a site survey', icon: 'calendar alternate outline', text: 'Book a site survey', value: 'site', href: "bookSiteSurvey" },
    { key: 'get more information', icon: 'mail', text: 'Get more information', value: 'info', href: "moreInfo" },
    { key: 'contact us', icon: 'talk', text: 'Contact us', value: 'contact', href: "contact"},

  ]

  const servicesOptions = [
    { key: 'Camera Systems', icon: 'video camera', text: 'Camera Systems', value: 'camera', href: "cameraSystems" },
    { key: 'Demarc Extensions', icon: 'code branch', text: 'Demarc Extensions', value: 'demarc', href: "demarcExtensions" },
    { key: 'Voice & Data', icon: 'ald', text: 'Voice & Data', value: 'voice', href: "voice+data" },
    { key: 'Audio Systems', icon: 'sound', text: 'Audio Systems', value: 'audio', href: "audioSystems" },
    { key: 'Projector Setups', icon: 'film', text: 'Projector Setups', value: 'projector', href: "projectorSetup" },
    { key: 'POS Wiring/Setup', icon: 'barcode', text: 'POS Wiring/Setup', value: 'pos', href: "posWiring" },
    { key: 'WAP Installs', icon: 'wifi', text: 'WAP Installs', value: 'wap', href: "wapInstall" },
    { key: 'Fiber Optics', icon: 'window minimize outline', text: 'Fiber Optics', value: 'fiber', href: "fiberOptics" },
    { key: 'Outside Plant', icon: 'home', text: 'Outside Plant', value: 'outside', href: "outsidePlant" },
  ]

  const productsOptions = [
    { key: 'CCTV', icon: 'tv', text: 'CCTV Products', value: 'CCTV', href: "cctvProducts" },
    { key: 'Voice & Data', icon: 'ald', text: `Voice & Data Products`, value: 'voice+dataProducts', href: "voice+dataProducts" },
    { key: 'Audio Visual', icon: 'file audio', text: 'Audio Visual Products', value: 'avProducts', href: "avProducts" },
  ]


const NavbarOptions = () => {


return (

<div className="col-12">
    
               <Menu className="btn-success white-text  mr-1 active" compact >
                        {/* <Button>Save</Button> */}
                        <Dropdown
                        text="Contact"
                      options={contactOptions} simple item />
                    </Menu>

                    <Menu className="btn-primary  mr-1 active" compact >
                        {/* <Button>Save</Button> */}
                        <Dropdown
                        text="Services"
                      options={servicesOptions} simple item />
                    </Menu>

                    <Menu className="btn-warning  mr-1 active" compact >
                        {/* <Button>Save</Button> */}
                        <Dropdown
                        text="Products"
                      options={productsOptions} simple item />
                    </Menu>
               </div>
)

}

export default NavbarOptions;