import React, { Component } from 'react';
import VoiceDataProducts from '../../widgets/index/voiceDataProducts';

import Herosection3 from '../../widgets/herosection/herosection3';

class Index4 extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <section>
                    <div className="container">
                        <Herosection3 />
                    </div>
                </section>
                <div className="page-content">
                    {/*feature start*/}
                    {/* <Feature /> */}
                    {/*feature end*/}
                    
                    {/*about start*/}
                    {/* <About /> */}
                    {/*about end*/}
                    {/*VoiceDataProducts start*/}
                    <VoiceDataProducts />
                    {/*services end*/}
                    {/*work start*/}
                    {/* <Work /> */}
                    {/*work end*/}
                    {/*testimonial start*/}
                    {/*testimonial end*/}
                    {/*blog start*/}
                    {/*blog end*/}
                </div>
            </div>
        );
    }
}

export default Index4;

