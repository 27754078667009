import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AccessControl from "../../assets/images/icon/accessControl.png";
import OrangeCable from "../../assets/images/icon/orangeCable.png";
import CameraSystem from "../../assets/images/icon/cameraSystem.png";
import WifiSymbol from "../../assets/images/icon/wifiSymbol.png";
import ComputerSupport from "../../assets/images/icon/computerSupport.png";
import Background   from '../../assets/images/bg/02.png';
import {Icon} from 'semantic-ui-react';

class Services extends Component {
    render() {
        return (
            <section className="custom-pt-1 custom-pb-2 bg-dark position-relative" data-bg-img={Background}>
                    <div className="container">
                        <div className="row align-items-end">
                        <div className="col-lg-4 col-md-6 mb-8 mb-lg-0 text-white">
                            <div> <span className="badge badge-warning-soft p-2">
                                <i className="la la-plug ic-3x rotation" />
                            </span>
                            <h2 className="mt-3 mb-0">Experienced professionals, best prices and fast service</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={CameraSystem} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Camera, security systems and other products</h5>
                            </div>
                            <p className="mb-0">Proper and simple installation of camera and security systems, for your security and tranquility. Also sale and installation of the most recent and highest rated products.</p>
                            <br />
                            <a href="/cctvProducts" className="btn btn-outline-primary btnAcsIntro">
CCTV Products
</a>

<a href="/cameraSystems" className="btn btn-outline-success btnAcsIntro">
Camera Systems</a>
<a href="/voice+dataProducts" className="btn btn-outline-info btnAcsIntro">
                        Voice & Data Products
                            </a>
                            <a href="/avProducts" className="btn btn-outline-secondary btnAcsIntro">
                        Audio Visual Products
                            </a>

                            
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6 mt-md-0">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={OrangeCable} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Cabling and installations</h5>
                            </div>
                            <p className="mb-0">Experienced and expert installation of all cabling needs.</p>
                            <br />
                            
                            <a href="/projectorSetup" className="btn btn-outline-success btnAcsIntro">
                        Projector Setup
                            </a>
                            <a href="/voice+data" className="btn btn-outline-info btnAcsIntro">
                        Voice & Data
                            </a>
                            <a href="/audioSystems" className="btn btn-outline-danger btnAcsIntro">
                        Audio Systems
                            </a>
                    
                         
                            <a href="/demarcExtensions" className="btn btn-outline-primary btnAcsIntro">
                        Demarc Extensions
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={WifiSymbol} alt="" />
                                </div>
                                <h5 className="m-0 text-light">WIFI(Wireless)</h5>
                            </div>
                            <p className="mb-0">Installation of new systems and service of existing networks, for the fastest most efficient bandwith.</p>
                            <br />
                            <a href="/wapInstall" className="btn btn-outline-primary btnAcsIntro">
                        WAP Installation (Wireless Access Point)
                            </a>
                            <a href="/fiberOptic" className="btn btn-outline-warning btnAcsIntro">
                        Fiber Optic
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={AccessControl} alt="" />
                                </div>
                                <h5 className="m-0 text-light">Professional wiring</h5>
                            </div>
                            <p className="mb-0">Professional results by certified technicians, in accordance to regulation and assistance in obtaining the required permits.</p>
                            <br />
                            <a href="/posWiring" className="btn btn-outline-success btnAcsIntro">
                        POS Wiring
                            </a>
                            <a href="/outsidePlant" className="btn btn-outline-info btnAcsIntro">
                        Outside Plant
                            </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-6">
                            <div className="bg-primary-soft rounded p-5">
                            <div className="d-flex align-items-center mb-4">
                                <div className="mr-3">
                                <img className="img-services" src={ComputerSupport} alt="" />
                                </div>
                                <h5 className="m-0 text-light">About us</h5>
                            </div>
                            <p className="mb-0">You can reach out to us below, let us know how we can use our professional expertise to help you</p>
                            <br />
                            <a href="/" className="btn btn-outline-warning btnAcsIntro">
                  <Icon className="arrow circle left large iconResizeUp"></Icon>

</a>

<a href="/contact" className="btn btn-success btnAcsIntro">
Contact us
</a>
<a href="/moreInfo" className="btn btn-info btnAcsIntro">
More Info
</a>
<a href="/bookSiteSurvey" className="btn btn-primary btnAcsIntro">
Book a site survey
</a></div>
                        </div>
                        </div>
                    </div>
                    <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    <div className="shape-1 bottom" style={{height: '200px', overflow: 'hidden'}}>
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                        <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                        </svg>
                    </div>
                    </section>
                   
        );
    }
}

export default Services;